import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer class="websiteFooter">
        2023. All rights reserved.
      </footer>
    </div>
  );
}

export default Footer;