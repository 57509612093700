import React from "react";

function CaseStudyApolloID() {
  return (
    <div className="body">
      <div className="pageElements">
        <div className="studyBody">
          <div className="modernSubTitle"> CASE STUDY </div>
          <div className="studyTitle"> Apollo Res </div>

          <div className="detailsSection">
            <div className="subDetailsSection">
              <div className="detailsTitle"> Problem </div>
              <div className="detailsParagraph">
                <a href="https://apps.apple.com/us/app/apollo-id/id1552644962" target="_blank" rel="noopener noreferrer"> <span className="link">Apollo ID</span> </a>
                 partner venues had no way of recognizing when their Members with Status were requesting reservations or expedited entry, and consequently were losing out on business from valuable guests.
              </div>
            </div>
            <div className="subDetailsSection rightSubDetailsSection">
              <div className="detailsTitle"> Solution </div>
              <div className="detailsParagraph">
                A web reservation service that integrates with the Apollo ID mobile app, where venues can access customers' Membership and profile details (social media, notes, spend data). Using this integrated data, they can recognize important guests and drive more personalized experiences.
              </div>
            </div>
          </div>

          <div className="detailsSection">
            <div className="detailsTitle"> Tech Stack </div>
            <div className="techPill javascriptPill"> JavaScript </div>
            <div className="techPill htmlPill"> HTML </div>
            <div className="techPill cssPill"> CSS </div>
            <div className="techPill googlePill"> Google APIs </div>
            <div className="techPill sketchPill"> Sketch </div>
            <div className="techPill trelloPill"> Trello </div>
          </div>

          <div className="detailsSection">
            <div className="detailsTitle"> My Role </div>
            <div className="detailsParagraph bulletList"> <ul>
              <li className="bulletItem"> Sole engineer & designer for project</li> 
              <li className="bulletItem"> Used Agile methods for product development, database architecture, UI/UX design, and user research to launch web application</li> 
              <li className="bulletItem"> Empowered Members of partner venues to more easily make reservations and communicate with staff, driving <b>8,400</b> bookings at partnered venues </li>
            </ul></div>
          </div>

          <hr></hr>
          <div className="detailsSection">
            <div className="secondaryTitle"> Product Demonstration </div>
            {/* <div className="detailsParagraph spaced">
              Product walkthrough <br></br>
            </div> */}
            <img src="/imageApolloResHome.svg" className="imageApolloResHome" alt="Screenshot of the Apollo Res homepage"></img>
          </div>
        
        
        
        
        
        </div>
      </div>
    </div>

  );
}

export default CaseStudyApolloID;