import React from "react";

function CaseStudyApolloID() {
  return (
    <div className="body">
      <div className="pageElements">
        <div className="studyBody">
          <div className="modernSubTitle"> CASE STUDY </div>
          <div className="studyTitle"> Apollo ID </div>
          <a href="https://apps.apple.com/us/app/apollo-id/id1552644962" target="_blank" rel="noopener noreferrer">
            <div className="productLink">View product </div>
          </a>

          <div className="detailsSection">
            <div className="subDetailsSection">
              <div className="detailsTitle"> Problem </div>
              <div className="detailsParagraph"> The modern dining & nightlife experience feels impersonal because it’s difficult for venues to be knowledgeable about guests. Guests have no way to signal who they are and the business they bring. </div>
            </div>
            <div className="subDetailsSection rightSubDetailsSection">
              <div className="detailsTitle"> Solution </div>
              <div className="detailsParagraph"> A centralized platform where guests & venues can build relationships. Guests can check in to earn points towards higher Status & better Benefits; venues can recognize important customers and build their Membership to improve retention. </div>
            </div>
          </div>

          <div className="detailsSection">
            <div className="detailsTitle"> Tech Stack </div>
            <div className="techPill swiftPill"> Swift </div>
            <div className="techPill javascriptPill"> JavaScript </div>
            <div className="techPill back4appPill"> Back4App </div>
            <div className="techPill parsePill"> Parse </div>
            <div className="techPill sketchPill"> Sketch </div>
            <div className="techPill invisionPill"> Invision </div>
            <div className="techPill trelloPill"> Trello </div>
          </div>

          <div className="detailsSection">
            <div className="detailsTitle"> My Role </div>
            <div className="detailsParagraph bulletList"> <ul>
              <li className="bulletItem"> Used agile processes for product development, database architecture, UI/UX design, and user research to build mobile hospitality technology platform</li> 
              <li className="bulletItem"> Led team of 2 engineers and 3 designers to successfully launch <b>Apollo ID</b> (consumer app) and <b>Apollo HQ</b> (venue app), resulting in <b>15,000+</b> user check-ins </li>
              <li className="bulletItem"> Implemented <b>Apollo Member Pricing</b> feature for guests to purchase food and drinks at reduced in-app pricing, processing <b>$200,000+</b> in payments </li>
              <li className="bulletItem"> Launched <b>Apollo Chat</b> feature so customers and venues could communicate; <b>5,800+</b> messages sent to-date, with <b>44%</b> increase in engagement in first <b>2 months</b> of release </li>
              <li className="bulletItem"> Enacted product go-to-market strategy to scale to <b>30+</b> venues, delivering <b>340,000+</b> platform interactions </li>
            </ul></div>
          </div>

          {/* <hr></hr> */}
          {/* <div className="detailsSection">
            <div className="secondaryTitle"> Apollo Member Pricing </div>
            <div className="detailsParagraph spaced">
              In February 2022, we launched the Apollo Member Pricing feature, as a way for customers who were members at our partner venues to purchase drinks & food more efficiently and at custom menu prices, depending on their Status. <br></br>
            </div>
            <div className="detailsParagraph">
              The goals of the feature were to:
              1. Increase penetration Add monetary value for users
              2. Increase user engagement, after checking in
            </div>
            <img src="/imageApolloMemberPricing.svg" className="diagramImage" alt="Before and after flow of Apollo Member Pricing"></img>
            5 months after launching the feature, we had processed over $200,000 in payments.
          </div> */}
        
        
        
        
        
        </div>
      </div>
    </div>

  );
}

export default CaseStudyApolloID;