import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <div className="websiteNavigation">
      <nav className="navbar navbar-expand navbar-dark">
        
        <div className="navSectionTitle">
          <NavLink className="websiteTitle" to="/">
            HARSH SHARMA
          </NavLink>
        </div>

        <div className="navSectionElements">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link pageTitle" to="/">
                  About
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link pageTitle" to="/contact">
                  Contact
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link pageTitle" to="/work">
                  Work
                </NavLink>
              </li>
            </ul>

            <div class="hamburger-menu">
              <input id="menu__toggle" type="checkbox" />
              <label class="menu__btn" for="menu__toggle">
                <span></span>
              </label>

              <ul class="menu__box">
                <li>
                  <NavLink className="nav-link pageTitle" to="/" onClick="menu__toggle">
                    About
                    <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
                
                <li className="nav-item">
                <NavLink className="nav-link pageTitle" to="/contact" onClick="menu__toggle">
                  Contact
                </NavLink>
                </li>
                
                <li className="nav-item">
                <NavLink className="nav-link pageTitle" to="/work" onClick="menu__toggle">
                  Work
                </NavLink>
                </li>
              </ul>
            </div>

          </div>

      </nav>
    </div>
  );
}

export default Navigation;
