import React from "react";

function Contact() {
  return (
    <div className="body">
     <div className="pageElements">
        <div className="subtitle">
          Contact
        </div>
        <p className="sectionParagraph">
          Please feel free to reach out.
          I am always open to meeting new people!
        </p>
        <p className="sectionParagraph">
          My email is&nbsp;
          <b><a href="mailto:harsh@harshsharma.net" target="_blank" rel="noopener noreferrer">
            harsh@harshsharma.net
          </a></b>
          &nbsp;or you can reach me on&nbsp;
          <b><a href="https://www.linkedin.com/in/harshsharma2/" target="_blank" rel="noopener noreferrer"> 
            LinkedIn.
          </a></b>
        </p>
            
      </div>
      
    </div>
  );
}

export default Contact;
