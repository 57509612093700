import React from "react";

function Home() {
  return (
    <div className="body">
      <div className="pageElements">
        <div className="subtitle">
          About
        </div>

        <div className="sectionBody">
          <p className="sectionParagraph">
            Harsh is currently a Product Manager at&nbsp;
              <u><a href="https://www.doordash.com/" target="_blank" rel="noopener noreferrer">
                DoorDash
              </a></u>
            &nbsp;redefining how parcels are delivered, fulfilled by our Dasher fleet from micro fulfillment sites.
          </p>     
          <p className="sectionParagraph">
            Prior to DoorDash, Harsh was the Product Lead on the founding team of&nbsp;
              <u><a href="https://apps.apple.com/us/app/apollo-id/id1552644962" target="_blank" rel="noopener noreferrer">
                Apollo ID
              </a></u>
            , a mobile platform driving membership and personalized experiences for guests at hospitality venues. Apollo ID has raised funds from leaders at JPMorgan, Bank of America, Wharton Angel Network, American Securities, & Apollo Global Management and continues to grow.
            Previously, Harsh was part of the Investment Banking Division at JPMorgan, where he was on M&A advisory teams for $3.4bn+ of clean technology & renewable M&A transactions.
          </p>
          <p className="sectionParagraph">
            Outside of work, Harsh is currently the President of the Associate Board for&nbsp;
              <u><a href="https://www.keennewyork.org/" target="_blank" rel="noopener noreferrer">
                KEEN New York
              </a></u>
            , a non-profit volunteer-led organization that provides recreational opportunities for kids and young adults with disabilities.
          </p>
          <p className="sectionParagraph">
            Harsh graduated from Duke University, where he double majored in Computer Science and Economics, and grew up in Charleston, SC. He enjoys Italian food, suspense novels, and is an avid Duke Basketball fan.
          </p>
        </div>
      </div>
      
    </div>

  );
}

export default Home;
