import React from "react";
import { NavLink } from "react-router-dom";

function WorkNew() {
  return (
    <div className="body">
     <div className="pageElements">
      <div className="subtitle" id="workTitle">
        Work
      </div>

      <div className="sectionBody">
        <div class="subpagePreview">
          <NavLink className="nav-link pageTitle" to="/CaseStudyApolloID" onClick="menu__toggle">
            <div className="previewTitle"> Case Study: Apollo ID </div>
            <p className="previewBody">
              Mobile hospitality app I was Product Lead for; provides membership and personalized experiences for guests at hospitality venues
            </p>
          </NavLink>
        </div>
      </div>

      <div className="sectionBody">
        <div class="subpagePreview">
          <NavLink className="nav-link pageTitle" to="/CaseStudyApolloRes" onClick="menu__toggle">
            <div className="previewTitle"> Case Study: Apollo Res </div>
            <p className="previewBody">
              Web reservation app I developed; connects to Apollo ID so Venue Members can more easily get priority reservations
            </p>
          </NavLink>
        </div>
      </div>



      
        
            
      </div>
    </div>
  );
}

export default WorkNew;
